import React, { useState, useRef, useEffect } from "react";
import { Button,  Card, Row, Col, Typography, Avatar, notification, Popover, Modal, Form, Input, Checkbox } from "antd";
import { PlusOutlined, CheckOutlined, CloseOutlined, EditOutlined, InfoCircleOutlined, ShareAltOutlined, CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import { API } from "aws-amplify";
import { onError } from "../../lib/errorLib.js";

import { CopyOutlined } from '@ant-design/icons';
const { Title, Text, Paragraph } = Typography;
const { TextArea } = Input;

// Avatar Card
const AvatarNameCard = ({userData, isMobile}) => {

    const [api, contextHolder] = notification.useNotification();

    const copyToClipboard = async () => {
        try {
          // Copy the text to the clipboard
          await navigator.clipboard.writeText(userData.orgId);
          // Show a success message using Ant Design's message component
          console.log('Text copied to clipboard!');
        } catch (err) {
          // Show an error message if something goes wrong
          console.log('Failed to copy text. Please try again.');
        }

        api.open({
            message: "Copied Team Id",
            icon: <CopyOutlined style={{ color: '#108ee9' }} /> 

        });
    };

    const orgIdPopoverContent = (
        <>
            <p><ShareAltOutlined /> Share this Id with your colleagues  </p>
            <p style={{marginTop: '-12px'}}> when they create their OD Shift accounts</p>
            <p style={{marginTop: '-12px', marginBottom: '0px'}}> and they can join your team.</p>
        </>
    );

    return (
        <>
            {contextHolder}
            <Card
            bordered={true}
            style={{ width: isMobile ? '90%' : '75%', padding: '0px', textAlign: 'left', marginBottom: '32px' }}
            >
                <Row align="top" justify="space-between">  
                    <Col span={24}>
                    {/* Container for avatar and text */}
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '-8px', marginBottom: '-16px' }}> 
                        <Row>
                            <Col>
                                {!isMobile ?
                                    <Avatar
                                        size={70}
                                        src={`${process.env.PUBLIC_URL}/Avatar.png`}
                                        alt="User Avatar"
                                        style={{ minWidth: '70px', maxWidth: '70px' }}
                                    />
                                :
                                    <></>
                                }
                            </Col>
                            <Col>
                                <div style={{ marginLeft: '18px' }}>  
                                    <Title level={3} style={{ marginTop: '0px' }}>
                                        {userData.firstName === undefined ? 
                                            <>
                                                username
                                            </>
                                        :
                                            <>
                                                {userData.firstName + " " + userData.lastName}
                                            </>
                                        }
                                    </Title>

                                    {userData.orgId ? (
                                        <div style={{ marginTop: 8 }}>
                                            {/* <p style={{ margin: 0 }}><b style={{ marginRight: '6px' }}>Org:</b> {orgData.orgName}</p> */}
                                            <div style={{ display: 'flex', alignItems: 'center', marginTop: 4 }}>
                                                <p style={{ margin: 0, marginBottom: '12px' }}><b style={{ marginRight: '6px' }}>Team ID:</b> {userData.orgId}</p>
                                                <Popover content={orgIdPopoverContent} style={{ marginLeft: 10, marginTop: '-6px' }}>
                                                    <InfoCircleOutlined style={{ marginLeft: 10, marginTop: '-16px' }} />
                                                </Popover>
                                                <Button type="secondary" onClick={copyToClipboard} style={{ marginLeft: 0, marginTop: '-16px' }}>
                                                    <CopyOutlined />
                                                </Button>
                                            </div>
                                        </div>
                                    ) : (
                                        <Paragraph style={{ marginTop: 8 }}>
                                            Bio (optional) if the user wants to add in a bio about themselves
                                        </Paragraph>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </div>
                    </Col>
                    {/* <Col span={4}>
                        <Button
                            icon={<EditOutlined />}
                            className="secondary-button button-size-default"
                            style={{ float: 'right', marginTop: 0 }}
                        >
                            Edit
                        </Button>
                    </Col> */}
                </Row>
            </Card>
        </>
    );
};

// Personal Clinic HEADER card
const PersonalInfoTitle = ({isMobile}) => {
    return (
        <Card
        bordered={true}
        style={{ width: isMobile ? '90%' : '75%', marginBottom: '4px', textAlign: 'left'}}  
        >
            <Title level={4} style={{ marginTop: -8, marginBottom: -8 }}>Personal and Contact Information</Title>
        </Card>
    );
};

// Personal & contact info card
const PersonalInfo = ({ userData, onLoginEvent, isMobile}) => {

    const [isOpenEditPersonalInfoModal, setIsOpenEditPersonalInfoModal] = useState(false);
    const [editProfileInformationButtonLoading, setEditProfileInformationButtonLoading] = useState(false);
    const [form] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();

    // Updated values (name, phone number, zip code, email)
    const [firstName, setFirstName] = useState(userData.firstName);
    const [lastName, setLastName] = useState(userData.lastName);
    const [phoneNumber, setPhoneNumber] = useState(userData.phoneNumber);
    const [zipCode, setZipCode] = useState(userData.zipCode);
    const [email, setEmail] = useState(userData.email);
    const [lat, setLat] = useState(userData.lat);
    const [lng, setLng] = useState(userData.lng);


    function validateForm() {
        if (firstName !== "" && lastName !== "" && phoneNumber !== "" && zipCode !== "" && email !== "" && zipCode.length === 5 ){
            return true;
        }else{
            return false;
        }
    }

    const handleFirstNameChange = (value) => {
        setFirstName(value);
        // console.log("adjusting first name: ", value);
    };

    const handleLastNameChange = (value) => {
        setLastName(value);
        // console.log("adjusting last name: ", value);
    };

    const handlePhoneNumberChange = (value) => {
        setPhoneNumber(value);
        // console.log("adjusting phone number: ", value);
    };


    // When changing ZipCode, need to get new lat long for new zip code using with Google Geocode API..
    const handleZipCodeChange = async (value) => {
        setZipCode(value);
        // console.log("adjusting zip code: ", value);
        // console.log("zip length: ", value.length);

        if(value.length === 5){
            // Need to set lat and long from new zip code
            // console.log("should set new lat and long based on new zip");

            // Get Lat Long from Zip to store in dynamoDB
            const geocoder = new window.google.maps.Geocoder();
            console.log("Geocoding zip code to retrieve lat/lng");
            const geocodingResponse = await geocoder.geocode({ address: value });
            let geocodedZipResult = [];
            if (geocodingResponse.results[0]) {
                const { lat, lng } = geocodingResponse.results[0].geometry.location;
                geocodedZipResult = {
                    lat: lat(), // .lat() function to get numeric latitude
                    lng: lng(), // .lng() function to get numeric longitude
                    formattedAddress: geocodingResponse.results[0].formatted_address,
                };
                console.log("Geocoded Zip Result:", geocodedZipResult);
                // console.log("geocodedZipResult.lat: ", geocodedZipResult.lat);
                // console.log("geocodedZipResult.lng: ", geocodedZipResult.lng);
                setLat(geocodedZipResult.lat);
                setLng(geocodedZipResult.lng);

            } else {
                console.error("No results found for the provided zip code.");
            }

        }
    };

    const handleEmailChange = (value) => {
        setEmail(value);
        // console.log("adjusting email: ", value);
    };

    // Open and Close Modals
    const openEditPersonalInfoModal = () => {
        setIsOpenEditPersonalInfoModal(true);
    }

    const closeEditPersonalInfoModal = () => {
        setIsOpenEditPersonalInfoModal(false);
    }

    const handleSavePersonalAndContactInfo = () => {
        setEditProfileInformationButtonLoading(true);
        // console.log("handleSavePersonalAndContactInfo button clicked...");

        const updateProfileInformationParams = {
            userType: userData.userType,
            firstName: firstName,
            lastName: lastName,
            phoneNumber: phoneNumber,
            zipCode: zipCode,
            email: email,
            lat: lat,
            lng: lng
        }

        // PUT to /userData is update
        try {
            return API.put("od-api", "/userData", { // updateUserData.js
                body: updateProfileInformationParams,
                }).then((data) => {
                console.log("response: ", data);
                if(data.statusCode === 200){ // check response to see if succesful or not.
                    api.open({
                        message: "Profile Information Added Succesfully",
                        icon: <CheckCircleTwoTone style={{ color: '#108ee9' }} />,
                    });
                    // SetIsEditDoctorProfileInformationModalOpen(false);
                    // setIsWelcomModalOpen(false);
                    setEditProfileInformationButtonLoading(false);
                    setIsOpenEditPersonalInfoModal(false);
        
                    // the page will not resfresh until we tell it to. Pull updated userData.
                    onLoginEvent();
                    // nav("/");
        
                }else{
                    api.open({
                        message: "Profile Information Was Not Added Succesfully",
                        icon: <CloseCircleTwoTone style={{ color: '#108ee9' }} />,
                    });

                    setEditProfileInformationButtonLoading(false);
                }
            });
        } catch (e) {
            onError(e);
            console.log("ERROR: ", e);
        }

    }

    return (
        <>
            {contextHolder}
            <Card style={{ width: isMobile ? '90%' : '75%' }}>
                <Row justify="space-between" align="top" style={{ marginBottom: '16px', marginTop: '-8px' }}>
                    <Col>
                        <Text style={{ color: '#8590A1' }}>Name</Text>
                        <Paragraph style={{ marginBottom: 0 }}>{userData.firstName + " " + userData.lastName}</Paragraph>
                    </Col>
                    <Col>
                        <Button 
                            icon={<EditOutlined />} 
                            className="secondary-button button-size-default"
                            onClick={openEditPersonalInfoModal}
                            >Edit</Button>
                    </Col>
                </Row>
                {userData.userType === 'clinicTeamMember' ? 
                    <></>
                : 
                    <>
                        <Row style={{ marginBottom: '0px' }}>
                            <Col span={24}>
                                <Text style={{ color: '#8590A1' }}>Phone number</Text>
                                <Paragraph>{userData.phoneNumber}</Paragraph>
                            </Col>
                        </Row>

                        <Row style={{ marginBottom: '0px' }}>
                            <Col span={24}>
                                <Text style={{ color: '#8590A1' }}>Zip code</Text>
                                <Paragraph>{userData.zipCode}</Paragraph>
                            </Col>
                        </Row>
                    </>
                }

                <Row style={{ marginBottom: '-20px' }}>
                    <Col span={24}>
                        <Text style={{ color: '#8590A1' }}>Email</Text>
                        <Paragraph>{userData.email}</Paragraph>
                    </Col>
                </Row>
            </Card>

            {/* Edit Personal Information Modal */}
            <Modal
                title="Edit Personal and Contact Information"
                centered
                open={isOpenEditPersonalInfoModal} 
                onOk={closeEditPersonalInfoModal} 
                onCancel={closeEditPersonalInfoModal} 
                footer={
                    <>
                        <Button className="secondary-button button-size-default" onClick={closeEditPersonalInfoModal}>
                            Back    
                        </Button>
                        <Button className="primary-button button-size-default" disabled={!validateForm()} loading={editProfileInformationButtonLoading} onClick={handleSavePersonalAndContactInfo}>
                            Save    
                        </Button>
                    </>
                }
            >
                <Form
                    style={{ marginTop: '12px' }}
                    form={form}
                    autoComplete="off"
                    layout="vertical"
                    initialValues={{
                        firstName: userData.firstName,
                        lastName: userData.lastName,
                        email: userData.email,
                        phone: userData.phoneNumber,
                        zipcode: userData.zipCode,
                    }}
                >
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                            label="First Name"
                            name="firstName"
                            rules={[{ required: true, message: 'Please input your first name!' }]}
                            >
                                <Input 
                                placeholder="Enter your first name"
                                onChange={(value) => {
                                    handleFirstNameChange(value.target.value);
                                }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                            label="Last Name"
                            name="lastName"
                            rules={[{ required: true, message: 'Please input your last name!' }]}
                            >
                                <Input 
                                placeholder="Enter your last name" 
                                onChange={(value) => {
                                    handleLastNameChange(value.target.value);
                                }}
                                />
                            </Form.Item>
                        </Col>

                        {userData.userType === 'clinicTeamMember' ? 
                            <>
                            </>
                        : 
                            <>
                                <Col span={24}>
                                    <Form.Item
                                    label="Phone number"
                                    name="phone"
                                    rules={[{ required: true, message: 'Please input your phone number!' }]}
                                    >
                                        <Input 
                                        placeholder="Enter your phone number"
                                        onChange={(value) => {
                                            handlePhoneNumberChange(value.target.value);
                                        }} 
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    {/* <Form.Item
                                    label="Zip code"
                                    name="zipcode"
                                    rules={[{ required: true, message: 'Please input your zip code!' }]}
                                    >
                                        <Input 
                                        placeholder="Enter your zip code"
                                        onChange={(value) => {
                                            handleZipCodeChange(value.target.value);
                                        }} 
                                        />
                                    </Form.Item> */}

                                    <Form.Item
                                        name="zipcode"
                                        label="Zip Code"
                                        rules={[
                                            { required: true, message: "Please input your zip code" },
                                            {
                                                pattern: /^[0-9]{5}$/, // Regular expression for 5 digits
                                                message: "Zip code must be exactly 5 digits",
                                            },
                                        ]}>
                                            <Input 
                                                type="text"
                                                placeholder="Enter your zip code"
                                                rules={[{ required: true, message: 'Please input your zip code' }]}
                                                onChange={(e) => {
                                                    const zip = e.target.value;
                                                    if (/^\d*$/.test(zip)) handleZipCodeChange(zip); // Only allow numeric input
                                                }}
                                                maxLength={5}
                                            />
                                    </Form.Item>
                                </Col>
                            </>
                        }

                        <Col span={24}>
                            <Form.Item
                            label="Email"
                            name="email"
                            rules={[
                                { required: true, message: 'Please input your email!' },
                                { type: 'email', message: 'Please enter a valid email!' },
                            ]}
                            >
                                <Input 
                                placeholder="Enter your email"
                                onChange={(value) => {
                                    handleEmailChange(value.target.value);
                                }} 
                                />
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>
                {/* <DoctorCard_CalendarInfo userData={currentlySelectedDoctorInformation}/> */}
            </Modal>
        </>

    );
};

//Clinics HEADER card
const ClinicCard_ClinicTitle = ({OpenModal_CreateAClinic, isMobile}) => {
    return (
        <Card
        bordered={true}
        style={{ width: isMobile ? '90%' : '75%', marginTop: '32px', marginBottom: '4px', textAlign: 'left' }} 
        >
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: -8, marginBottom: -8 }}>
                <Title level={4} style={{ marginTop: -8, marginBottom: -8 }}>Clinics</Title>
                <Button onClick={OpenModal_CreateAClinic} className="secondary-button button-size-default" icon={<PlusOutlined /> }>Add clinic</Button>
            </div>
        </Card>
    );
};

// Doctor - Professional TITLE card
const DoctorCard_ProfessionalTitle = ({isMobile}) => {
    return (
        <Card
            bordered={true}
            style={{ width: isMobile ? '90%' : '75%', marginBottom: '4px', marginTop: '32px',  textAlign: 'left'}}  
        >
            <Title level={4} style={{ marginTop: -8, marginBottom: -8 }}>Professional Information</Title>
        </Card>
    );
};

// Doctor - Professional INFO card
const DoctorCard_ProfessionalInfo = ({ userData, onLoginEvent, isMobile}) => {

    const [isOpenEditProfessionalInfoModal, setIsOpenEditProfessionalInfoModal] = useState(false);
    const [editProfessionalInformationButtonLoading, setEditProfessionalInformationButtonLoading] = useState(false);
    const [form] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();

    const [licenseNumber, setLicenseNumber] = useState(userData.licenseNumber);
    const [npiNumber, setNpiNumber] = useState(userData.licenseNumber);
    const [linkedInProfileUrl, setLinkedInProfileUrl] = useState(userData.licenseNumber);

    function validateForm() {
        if(licenseNumber !== "" && npiNumber !== "" && linkedInProfileUrl !== ""){
            return true;
        }else{
            return false;
        }
    }

    const handleLicenseNumberChange = (value) => {
        setLicenseNumber(value);
        // console.log("adjusting first name: ", value);
    };

    const handleNPINumberChange = (value) => {
        setNpiNumber(value);
        // console.log("adjusting last name: ", value);
    };

    const handleLinkedInProfileUrlChange = (value) => {
        setLinkedInProfileUrl(value);
        // console.log("adjusting phone number: ", value);
    };

    // Open and Close Modals
    const openEditProfessionalInfoModal = () => {
        setIsOpenEditProfessionalInfoModal(true);
    }

    const closeEditProfessionalInfoModal = () => {
        setIsOpenEditProfessionalInfoModal(false);
    }

    const handleSaveProfessionalInfo = () => {
        setEditProfessionalInformationButtonLoading(true);

        const updateProfileInformationParams = {
            userType: userData.userType,
            licenseNumber: licenseNumber,
            npiNumber: npiNumber,
            linkedInProfileUrl: linkedInProfileUrl
        }

        // PUT to /userData is update
        try {
            return API.put("od-api", "/userData", { // updateUserData.js
                body: updateProfileInformationParams,
                }).then((data) => {
                console.log("response: ", data);
                if(data.statusCode === 200){ // check response to see if succesful or not.
                    api.open({
                        message: "Professional Information Added Succesfully",
                        icon: <CheckCircleTwoTone style={{ color: '#108ee9' }} />,
                    });
                    // SetIsEditDoctorProfileInformationModalOpen(false);
                    // setIsWelcomModalOpen(false);
                    setEditProfessionalInformationButtonLoading(false);
                    setIsOpenEditProfessionalInfoModal(false);
        
                    // the page will not resfresh until we tell it to. Pull updated userData.
                    onLoginEvent();
                    // nav("/");
        
                }else{
                    api.open({
                        message: "Professional Information Was Not Added Succesfully",
                        icon: <CloseCircleTwoTone style={{ color: '#108ee9' }} />,
                    });

                    setEditProfessionalInformationButtonLoading(false);
                }
            });
        } catch (e) {
            onError(e);
            console.log("ERROR: ", e);
        }

    }

    return (

        <>
            {contextHolder}
            <Card style={{ width: isMobile ? '90%' : '75%', marginBottom: '32px' }}>
                <Row justify="space-between" align="top" style={{ marginTop: '-8px' }}>
                    <Col>
                        <Text style={{ color: '#8590A1' }}>Optometry License</Text>
                        <Paragraph>{userData.licenseNumber}</Paragraph>
                    </Col>
                    <Col>
                        <Button icon={<EditOutlined />} className="secondary-button button-size-default" onClick={openEditProfessionalInfoModal}>Edit</Button>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <Text style={{ color: '#8590A1' }}>NPI number</Text>
                        <Paragraph>{userData.npiNumber}</Paragraph>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                    <Text style={{ color: '#8590A1' }}>LinkedIn Profile Url</Text>
                    <Paragraph style={{ marginBottom: '-8px' }}><a target="_blank" href={userData.linkedInProfileUrl}>{userData.linkedInProfileUrl}</a></Paragraph>
                    </Col>
                </Row>
            </Card>

            {/* Edit Professional Information Modal */}
            <Modal
                title="Edit Professional Information"
                centered
                open={isOpenEditProfessionalInfoModal} 
                onOk={closeEditProfessionalInfoModal} 
                onCancel={closeEditProfessionalInfoModal} 
                footer={
                    <>
                        <Button className="secondary-button button-size-default" onClick={closeEditProfessionalInfoModal}>
                            Back    
                        </Button>
                        <Button className="primary-button button-size-default" disabled={!validateForm()} loading={editProfessionalInformationButtonLoading} onClick={handleSaveProfessionalInfo}>
                            Save    
                        </Button>
                    </>
                }
            >
                <Form
                    style={{ marginTop: '12px' }}
                    form={form}
                    autoComplete="off"
                    layout="vertical"
                    initialValues={{
                        licenseNumber: userData.licenseNumber,
                        npiNumber: userData.npiNumber,
                        linkedInProfileUrl: userData.linkedInProfileUrl,
                    }}
                >
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                            label="License Number"
                            name="licenseNumber"
                            rules={[{ required: true, message: 'Please input your license number!' }]}
                            >
                                <Input 
                                placeholder="Enter your license number"
                                onChange={(value) => {
                                    handleLicenseNumberChange(value.target.value);
                                }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                            label="NPI Number"
                            name="npiNumber"
                            rules={[{ required: true, message: 'Please input your NPI number!' }]}
                            >
                                <Input 
                                placeholder="Enter your NPI number" 
                                onChange={(value) => {
                                    handleNPINumberChange(value.target.value);
                                }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                            label="LinkedIn Profile Url"
                            name="linkedInProfileUrl"
                            rules={[{ required: true, message: 'Please input your profile url!' }]}
                            >
                                <Input 
                                placeholder="Enter your LinkedIn profile url"
                                onChange={(value) => {
                                    handleLinkedInProfileUrlChange(value.target.value);
                                }} 
                                />
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>
            </Modal>
        </>

    );
};


// Doctor - Preferences TITLE card
const DoctorCard_PreferencesTitle = ({isMobile}) => {
    return (
        <Card
        bordered={true}
        style={{  width: isMobile ? '90%' : '75%', marginBottom: '4px', textAlign: 'left'}}  
        >
            <Title level={4} style={{ marginTop: -8, marginBottom: -8 }}>Preferences </Title>
        </Card>
    );
};

// // Doctor - Preferences INFO card
const DoctorCard_PreferencesInfo = ({ userData, onLoginEvent, isMobile }) => {
    const [isOpenEditPreferencesInfoModal, setIsOpenEditPreferencesInfoModal] = useState(false);
    const [editPreferencesInformationButtonLoading, setEditPreferencesInformationButtonLoading] = useState(false);
    const [form] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();
  
    // Log userData for debugging
    console.log("userData: ", userData);
    console.log("userData.isGlassesAndContacts: ", userData.isGlassesAndContacts);
    console.log("userData.isMedical: ", userData.isMedical);
  
    // Open and Close Modals
    const openEditPreferencesInfoModal = () => {
      setIsOpenEditPreferencesInfoModal(true);
      form.setFieldsValue({
        isGlassesAndContacts: userData.isGlassesAndContacts,
        isMedical: userData.isMedical,
        doctorNotes: userData.doctorNotes
      });
    };
  
    const closeEditPreferencesInfoModal = () => {
      setIsOpenEditPreferencesInfoModal(false);
    };
  
    const handleSavePreferencesInfo = async () => {
      setEditPreferencesInformationButtonLoading(true);
      
      const updatedValues = form.getFieldsValue();
      const updatePreferencesInformationParams = {
        userType: userData.userType,
        ...updatedValues // Use the values directly from the form
      };
  
      try {
        const data = await API.put("od-api", "/userData", {
          body: updatePreferencesInformationParams
        });
        console.log("response: ", data);
        if (data.statusCode === 200) {
          api.open({
            message: "Preferences Information Added Successfully",
            icon: <CheckCircleTwoTone style={{ color: '#108ee9' }} />,
          });
          setEditPreferencesInformationButtonLoading(false);
          setIsOpenEditPreferencesInfoModal(false);
          onLoginEvent(); // Refreshes the user data
        } else {
          api.open({
            message: "Preferences Information Was Not Added Successfully",
            icon: <CloseCircleTwoTone style={{ color: '#108ee9' }} />,
          });
          setEditPreferencesInformationButtonLoading(false);
        }
      } catch (e) {
        console.error("Error updating preferences:", e);
        setEditPreferencesInformationButtonLoading(false);
      }
    };
  
    return (
      <>
        {contextHolder}
        <Card style={{ width: isMobile ? '90%' : '75%', marginBottom: '32px'}}>
          <Row justify="space-between" align="top" style={{ marginTop: '-8px' }}>
            <Col>
              {userData.isGlassesAndContacts
                ? <Paragraph><CheckOutlined /> Routine (glasses and contacts)</Paragraph>
                : <Paragraph><CloseOutlined /> Routine (glasses and contacts)</Paragraph>
              }
            </Col>
            <Col>
              <Button icon={<EditOutlined />} className="secondary-button button-size-default" onClick={openEditPreferencesInfoModal}>Edit</Button>
            </Col>
          </Row>
  
          {userData.isMedical
            ? <Paragraph><CheckOutlined /> Medical optometry</Paragraph>
            : <Paragraph><CloseOutlined /> Medical optometry</Paragraph>
          }
  
          {userData.doctorNotes && (
            <Row>
              <Col span={24}>
                <Text style={{ color: '#8590A1' }}>Notes about me</Text>
                <Paragraph>{userData.doctorNotes}</Paragraph>
              </Col>
            </Row>
          )}
        </Card>
  
        {/* Edit Preferences Information Modal */}
        <Modal
          title="Edit Preferences Information"
          centered
          open={isOpenEditPreferencesInfoModal}
          onOk={closeEditPreferencesInfoModal}
          onCancel={closeEditPreferencesInfoModal}
          footer={[
            <Button key="back" className="secondary-button button-size-default" onClick={closeEditPreferencesInfoModal}>Back</Button>,
            <Button key="submit" type="primary" className="primary-button button-size-default" loading={editPreferencesInformationButtonLoading} onClick={handleSavePreferencesInfo}>
              Save
            </Button>
          ]}
        >
          <Form
            form={form}
            autoComplete="off"
            layout="vertical"
            initialValues={{
              isGlassesAndContacts: userData.isGlassesAndContacts,
              isMedical: userData.isMedical,
              doctorNotes: userData.doctorNotes
            }}
          >
            <Row>
                <Col span={12}>
                    <Form.Item name="isGlassesAndContacts" valuePropName="checked">
                        <Checkbox>Routine (glasses and contacts)</Checkbox>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="isMedical" valuePropName="checked">
                        <Checkbox>Medical</Checkbox>
                    </Form.Item>
                </Col>
            </Row>
            <Row style={{marginTop: '-16px', marginBottom: '-20px'}}>
                <Col span={24}>
                    <Form.Item name="doctorNotes" label="Notes">
                        <TextArea rows={2} maxLength={600} placeholder="Add notes" />
                    </Form.Item>
                </Col>
            </Row>
          </Form>
        </Modal>
      </>
    );
  };

// IN CLINIC CALENDAR
///////////////////////////////////////////////
// Doctor Info within Accept Doctor Flow Calendar from Clinic Side
// Doctor - Personal Info and Preferences Card
const DoctorCard_CalendarInfo = (userData) => {

    const [api, contextHolder] = notification.useNotification();

    const copyToClipboard = async () => {
        try {
          // Copy the text to the clipboard
          await navigator.clipboard.writeText(userData.userData.email);
          // Show a success message using Ant Design's message component
          console.log('Text copied to clipboard!');
        } catch (err) {
          // Show an error message if something goes wrong
          console.log('Failed to copy text. Please try again.');
        }

        api.open({
            message: "Copied Email",
            icon: <CopyOutlined style={{ color: '#108ee9' }} /> 

        });
    };

    return (
        <>
            {contextHolder}
            <Card style={{ width: '100%', marginBottom: '8px'}}>
            <p style={{ fontSize: '14px', marginTop:'-8px'}}><b>Personal Information:</b></p>
            {/* Need new rows and columns here... */}
            <Row>
                <Col span={14}>
                    {/* Personal Info */}
                    <Row justify="space-between" align="top" style={{ marginBottom: '16px', marginTop: '-8px' }}>
                        <Col>
                            <Text style={{ color: '#8590A1' }}>Name</Text>
                            <Paragraph style={{ marginBottom: 0 }}>{userData.userData.firstName + " " + userData.userData.lastName}</Paragraph>
                        </Col>
                    </Row>

                    <Row style={{ marginBottom: '0px' }}>
                        <Col span={24}>
                            <Text style={{ color: '#8590A1' }}>Phone number</Text>
                            <Paragraph>{userData.userData.phoneNumber}</Paragraph>
                        </Col>
                    </Row>

                    <Row style={{ marginBottom: '0px' }}>
                        <Col span={24}>
                            <Text style={{ color: '#8590A1' }}>Zip code</Text>
                            <Paragraph>{userData.userData.zipCode}</Paragraph>
                        </Col>
                    </Row>

                    <Row style={{ marginBottom: '-20px' }}>
                        <Col span={24}>
                            <Text style={{ color: '#8590A1' }}>Email</Text>   
                            <Row>
                                <Col span={24} style={{ display: 'flex', alignItems: 'center' }}>
                                    <Paragraph style={{ marginBottom: 0 }}>{userData.userData.email}</Paragraph>
                                    <Button type="secondary" onClick={copyToClipboard} style={{ marginLeft: '8px' }}>
                                        <CopyOutlined />
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>

                <Col span={10}>
                    {/* Doctor Information  */}
                    <Row justify="space-between" align="top" style={{ marginTop: '-8px' }}>
                        <Col>
                            <Text style={{ color: '#8590A1' }}>Optometry License</Text>
                            <Paragraph>{userData.userData.licenseNumber}</Paragraph>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24}>
                            <Text style={{ color: '#8590A1' }}>NPI number</Text>
                            <Paragraph>{userData.userData.npiNumber}</Paragraph>
                        </Col>
                    </Row>
                    {userData.userData.linkedInProfileUrl != undefined ?
                        <Row>
                            <Col span={24}>
                                <Text style={{ color: '#8590A1' }}>LinkedIn Profile Url</Text>
                                <Paragraph style={{ marginBottom: '-8px' }}><a target="_blank" href={userData.userData.linkedInProfileUrl}>{userData.userData.linkedInProfileUrl}</a></Paragraph>
                            </Col>
                        </Row>
                    : 
                        <></>
                    }

                </Col>
            </Row>
            </Card>
            {/* Doctor Preferences */}
            <Card style={{ width: '100%'}}>
                <p style={{ fontSize: '14px', marginTop:'-8px'}}><b>Preferences:</b></p>
                <Row >
                    <Col span={14}>

                        {userData.userData.isGlassesAndContacts === true
                        ?
                            <Row justify="space-between" align="top" style={{ marginTop: '4px' }}>
                                <Paragraph><CheckOutlined /> Routine (glasses and contacts)</Paragraph>
                            </Row>
                        :
                            <Row justify="space-between" align="top" style={{ marginTop: '-8px' }}>
                                <Paragraph><CloseOutlined /> Routine (glasses and contacts)</Paragraph>
                            </Row>
                        }

                        {userData.userData.isMedical === true
                        ? 
                            <Row style={{ marginBottom: '16px' }}>
                                <Col span={24}>
                                <Paragraph><CheckOutlined /> Medical optometry</Paragraph>
                                </Col>
                            </Row>
                        : 
                            <Row style={{ marginBottom: '16px' }}>
                                <Col span={24}>
                                <Paragraph><CloseOutlined /> Medical optometry</Paragraph>
                                </Col>
                            </Row>
                        }
                    </Col>
                    {userData.userData.doctorNotes != undefined ?
                        <Col span={10}>
                            <Text style={{ color: '#8590A1' }}>Notes about me</Text>
                            <Paragraph style={{ marginBottom: '-8px' }}>{userData.userData.doctorNotes}</Paragraph>
                        </Col>
                    : 
                        <></>
                    }
                </Row>
            </Card>
        </>
    );
};


// IN DOCTOR CALENDAR
///////////////////////////////////////////////
// REFACTOR: abstract this to another file... this should not be in this profileComponents.js file
// Clinic Info within Doctor Calendar when selecting on a pending or scheduled shift.
const ClinicInfoCard_DoctorCalendar = ({clinicData}) => {

    console.log("clinicData within ClinicInfoCard_DoctorCalendar: ", clinicData);

    const [api, contextHolder] = notification.useNotification();

    const copyToClipboard = async () => {
        try {
          // Copy the text to the clipboard
          await navigator.clipboard.writeText(clinicData.clinicEmail);
          // Show a success message using Ant Design's message component
          console.log('Text copied to clipboard!');
        } catch (err) {
          // Show an error message if something goes wrong
          console.log('Failed to copy text. Please try again.');
        }

        api.open({
            message: "Copied Email",
            icon: <CopyOutlined style={{ color: '#108ee9' }} /> 

        });
    };

    return (
        <>
            {contextHolder}
            <Card style={{ width: '100%', marginBottom: '8px'}}>
            {/* <p style={{ fontSize: '14px', marginTop:'-8px'}}><b>Clinic Information:</b></p> */}
            {/* Need new rows and columns here... */}
            <Row>
                <Col span={24}>
                    {/* Personal Info */}
                    <Row justify="space-between" align="top" style={{ marginBottom: '16px', marginTop: '-8px' }}>
                        <Col>
                            <Text style={{ color: '#8590A1' }}>Name</Text>
                            <Paragraph style={{ marginBottom: 0 }}>{clinicData.clinicName}</Paragraph>
                        </Col>
                    </Row>

                    <Row style={{ marginBottom: '0px' }}>
                        <Col span={24}>
                            <Text style={{ color: '#8590A1' }}>Phone number</Text>
                            <Paragraph>{clinicData.clinicPhoneNumber}</Paragraph>
                        </Col>
                    </Row>

                    <Row style={{ marginBottom: '0px' }}>
                        <Col span={24}>
                            <Text style={{ color: '#8590A1' }}>Address</Text>
                            <Paragraph>{clinicData.clinicAddress}</Paragraph>
                        </Col>
                    </Row>

                    <Row style={{ marginBottom: '0px' }}>
                        <Col span={24}>
                            <Text style={{ color: '#8590A1' }}>Website</Text>
                                <Paragraph><a href={"https://" + clinicData.clinicWebsite} target="_blank">{clinicData.clinicWebsite}</a></Paragraph>
                        </Col>
                    </Row>

                    <Row style={{ marginBottom: '-20px' }}>
                        <Col span={24}>
                            <Text style={{ color: '#8590A1' }}>Email</Text>   
                            <Row>
                                <Col span={24} style={{ display: 'flex', alignItems: 'center' }}>
                                    <Paragraph style={{ marginBottom: 0 }}>{clinicData.clinicEmail}</Paragraph>
                                    <Button type="secondary" onClick={copyToClipboard} style={{ marginLeft: '8px' }}>
                                        <CopyOutlined />
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
            </Card>
            {/* Clinic Preferences */}
            <Card style={{ width: '100%'}}>
                <p style={{ fontSize: '14px', marginTop:'-8px'}}><b>Preferences:</b></p>
                <Row >
                    <Col span={14}>

                        {clinicData.clinicGlassesContactsBool === true
                        ?
                            <Row justify="space-between" align="top" style={{ marginTop: '4px' }}>
                                <Paragraph><CheckOutlined /> Routine (glasses and contacts)</Paragraph>
                            </Row>
                        :
                            <Row justify="space-between" align="top" style={{ marginTop: '-8px' }}>
                                <Paragraph><CloseOutlined /> Routine (glasses and contacts)</Paragraph>
                            </Row>
                        }

                        {clinicData.clinicMedicalBool === true
                        ? 
                            <Row style={{ marginBottom: '16px' }}>
                                <Col span={24}>
                                <Paragraph><CheckOutlined /> Medical optometry</Paragraph>
                                </Col>
                            </Row>
                        : 
                            <Row style={{ marginBottom: '16px' }}>
                                <Col span={24}>
                                <Paragraph><CloseOutlined /> Medical optometry</Paragraph>
                                </Col>
                            </Row>
                        }
                    </Col>
                    {clinicData.clinicNotes != undefined ?
                        <Col span={10}>
                            <Text style={{ color: '#8590A1' }}>Notes</Text>
                            <Paragraph style={{ marginBottom: '-8px' }}>{clinicData.clinicNotes}</Paragraph>
                        </Col>
                    : 
                        <></>
                    }
                </Row>
            </Card>
        </>
    );
};


// Export them from this file
export { 
    AvatarNameCard,
    PersonalInfoTitle, 
    PersonalInfo, 
    ClinicCard_ClinicTitle, 
    DoctorCard_ProfessionalTitle, 
    DoctorCard_ProfessionalInfo,
    DoctorCard_PreferencesTitle, 
    DoctorCard_PreferencesInfo,
    DoctorCard_CalendarInfo,
    ClinicInfoCard_DoctorCalendar
};